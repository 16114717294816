export const REDIRECT_AND_STORAGE_KEYS = [
    {
        redirectString:"generator",
        storageKey: "generator",
        ringbaKey:"generator"
    },
    {
        redirectString:"click_id",
        storageKey: "click_id",
        ringbaKey:"click_id"
    },
    {
        redirectString:"utm_source",
        storageKey: "utm_source",
        ringbaKey:"utm_source"
    },
    {
        redirectString:"utm_medium",
        storageKey: "utm_medium",
        ringbaKey:"utm_medium"
    },
    {
        redirectString:"utm_campaign",
        storageKey: "utm_campaign",
        ringbaKey:"utm_campaign"
    },
    {
        redirectString:"utm_adset",
        storageKey: "utm_adset",
        ringbaKey:"utm_adset"
    },
    {
        redirectString:"utm_ad",
        storageKey: "utm_ad",
        ringbaKey:"utm_ad"
    },
    {
        redirectString:"site_id",
        storageKey: "site_id",
        ringbaKey:"site_id"
    },
    {
        redirectString:"language",
        storageKey: "language",
        ringbaKey:"language"
    },
    {
        redirectString:"device",
        storageKey: "device",
        ringbaKey:"device"
    },
    {
        redirectString:"interest",
        storageKey: "interest",
        ringbaKey:"interest"
    },
    {
        redirectString:"fbclid",
        storageKey: "fbclid",
        ringbaKey:"fbclid"
    },
    {
        redirectString:"gclid",
        storageKey: "gclid",
        ringbaKey:"gclid"
    },

    {
        redirectString:"grbaid",
        storageKey: "grbaid",
        ringbaKey:"grbaid"
    },
    {
        redirectString:"wbraid",
        storageKey: "wbraid",
        ringbaKey:"wbraid"
    }
]