import React, { useEffect } from "react";
import "./Navbar.scss";
import { useInitRingba } from "../../hooks/rgba";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEventID } from "../../hooks/useEventID";

const phoneicon = "/assets/images/phone.svg";
const logo = "/assets/images/logo.png";

export const Navbar = () => {
  const { number, staticNumber } = useInitRingba();
  const [search] = useSearchParams();
  const eventID = useEventID();

  const handleCallEventClick = () => {
    if (search.get("utm_source") == "facebook") {
      window.fbcFunc &&
        window.fbcFunc("track", "Contact", {
          eventID: eventID,
        });
    }
  };
  return (
    <div className="navbar">
      <nav>
        <div className="logoholder">
          <div className="logo">
            <img src={logo} alt="logo" />
            <div className="sitename">
              <h1>{process.env.REACT_APP_DOMAIN_NAME || "QualifyAca.com"}</h1>
              <h2>a non-government aca site</h2>
            </div>
          </div>
        </div>
        {true ? (
          <a href={`tel:${number || staticNumber}`} onClick={handleCallEventClick} id="form-end-contact">
            <div className="phone-icon">
              <img src={phoneicon} alt="logo" />
            </div>
            <div className="phone-number-holder">
              <div className="text-phone-number">
                CALL TOLL-FREE M-F 7AM-10PM CST
              </div>
              <div className="toll-free-number-holder">
                <div className="available-blinker"></div>
                <div className="toll-free-number">
                  <span id="font-end-contact-number" className="font-bold">
                    {number || staticNumber}{" "}
                  </span>
                  <span className="font-semibold">(TTY 711)</span>
                </div>
              </div>
            </div>
          </a>
        ) : undefined}
      </nav>
    </div>
  );
};

export default Navbar;
