import React, { useEffect } from "react";
import ROUTES from "../../constant/routes";
import "./Footer.scss";
import { useEventID } from "../../hooks/useEventID";

const logo = "/assets/images/logo.png";

export const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-holder">
          <div className="logoholder">
            <div className="logo">
              <img src={logo} alt="logo" />
              <div className="sitename">
                <h1>{process.env.REACT_APP_DOMAIN_NAME || "QualifyAca.com"}</h1>
                <h2>a non-government aca site</h2>
              </div>
            </div>
          </div>
          <div className="footer-horizontal-divider"></div>
          <div className="font-13 color-dark-grey">
            Disclaimer: {process.env.REACT_APP_DOMAIN_NAME || "QualifyAca.com"}{" "}
            is a referral source that provides information and access to a
            helpline to match consumers with companies that may provide certain
            insurance coverage to them. qualifyaca.com does not act as an
            insurance broker and does not make decisions about insurance
            coverage that may be available to you. qualifyaca.com doesn’t
            promise a specific outcome or the results you may achieve by calling
            the helpline. The helpline is free to call but the services or
            programs that you pursue may have costs associated with them.
            Neither qualifyaca.com nor any of the supplemental insurance plans
            to which you may be connected are endorsed by the U.S. Government or
            the federal health program.
            <a target="_blank" href={ROUTES.termsAndCondition}>
              Terms and Conditions
            </a>
            , and{" "}
            <a target="_blank" href={ROUTES.privacyPolicy}>
              Privacy Policy
            </a>
            . Not affiliated with the U. S. government or federal Medicare
            program. A licensed agent may contact you regarding this
            insurance-related information.
          </div>
          <div className="footer-links color-dark-grey">
            <div className="font-13">
              Copyright © 2022 | {process.env.REACT_APP_WEB_NAME}
            </div>
            <div className="footer-vertical-divider"></div>
            <div className="font-13">
              <a target="_blank" href={ROUTES.privacyPolicy}>
                PRIVACY POLICY
              </a>
            </div>
            <div className="footer-vertical-divider"></div>
            <div className="font-13">
              <a target="_blank" href={ROUTES.termsAndCondition}>
                TERMS OF USE
              </a>
            </div>
          </div>
        </div>
      </div>
      <AddEventId />
    </>
  );
};

function AddEventId() {
  const eventID = useEventID();
  const viewContentKey = "PageView";

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.fbcFunc && !sessionStorage.getItem(viewContentKey)) {
        window.fbcFunc("track", "PageView", { eventID: eventID });
        sessionStorage.setItem(viewContentKey, "true");
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
    }, 20000);

    return () => clearInterval(interval);
  }, []);
  return <></>;
}

export default Footer;
