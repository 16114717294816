import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FloatingCard from "../../components/FloatingCard/FloatingCard";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { REDIRECT_AND_STORAGE_KEYS } from "../../constant/queryStrings";
import { useInitRingba, useRgbaHook } from "../../hooks/rgba";
import "../prelander.scss";
import { Rating } from "./Ratings/Ratings";
import axios from "axios";

const image1 = "/assets/img/logos/united-healthcare.svg";
const image2 = "/assets/img/logos/anthem-bluecross.svg";
const image3 = "/assets/img/logos/aetna.svg";
const image4 = "/assets/img/logos/humana.svg";
const image5 = "/assets/img/logos/cigna.svg";
const image6 = "/assets/img/logos/kaiser-permanente.svg";

const PreLander1 = ({ setForm }) => {
  let nav = useNavigate();
  const { number, staticNumber } = useInitRingba();
  const { storeRgbaData } = useRgbaHook();
  const [search] = useSearchParams();

  const init = () => {
    REDIRECT_AND_STORAGE_KEYS.forEach((obj) => {
      storeRgbaData(obj.ringbaKey, search.get(obj.redirectString));
    });
  };

  const [cityStateResponse, setCityStateResponse] = useState({
    city: "",
    state: "",
  });

  const cityAddress = async () => {
    try {
      const { data } = await axios.get(
        "https://funnel.improveourcredit.com/ip?key=askdjaslkdjaskjdsla"
      );
      console.log(data);
      setCityStateResponse({
        state: data.subdivisions[0].names.en,
        city: data.city.names.en,
        country: data.country.names.en,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
    cityAddress();
  }, []);

  return (
    <div className="main">
      <Navbar number={number} staticNumber={staticNumber} />
      <div className="pre-form">
        <div className="pre-form-holder">
          <div className="pre-form-container">
            <div className="bold font-40 color-primary headline">
              New Medicare Benefits Update
            </div>

            <div className="font-24 color-primary sub-headline">
              Americans
              {" " + cityStateResponse["city"]
                ? " " + cityStateResponse["city"] + ","
                : ""}{" "}
              {cityStateResponse["state"] + " "}Ages 65+ In Have 10 days to
              claim additional benefits & get up to $240 monthly in OTC
              benefits.
            </div>

            <div className="color-dark-grey paragraph">
              Thanks to a new Medicare Advantage package, Americans age 65+
              could get $148.50* monthly added to their social security check
              with No-Cost Vision & Dental Benefits.
            </div>
            <a
              href="https://track.health-benefits.co/click"
              id="landerclick"
              className="qualify-button"
            >
              See If You Qualify
            </a>
          </div>
          <div className="image-section carrier-images">
            <img src={image1} alt="rating section" />
            <img src={image2} alt="rating section" />
            <img src={image3} alt="rating section" />
            <img src={image4} alt="rating section" />
            <img src={image5} alt="rating section" />
            <img src={image6} alt="rating section" />
          </div>
        </div>

        <FloatingCard />
      </div>

      <FormStart />

      <Footer number={number} />
    </div>
  );
};

const FormStart = () => {
  return (
    <div className="form-start">
      <Rating />
    </div>
  );
};

export default PreLander1;
